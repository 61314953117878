import React from "react";
import { Helmet } from "react-helmet";

export default function Application() {
    return (
        <Helmet>
            <meta charSet="utf-8" />
            <title>Marvel</title>
            <script async src="https://www.googletagmanager.com/gtag/js?id=G-PC28QBX1Z4"></script>
            <script>
                {`window.dataLayer = window.dataLayer || [];
                function gtag(){dataLayer.push(arguments);}
                gtag('js', new Date());
                gtag('config', 'G-PC28QBX1Z4');`}
            </script>
        </Helmet>
    );
}
